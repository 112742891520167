// Footer.js
import React from 'react';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';  // Ensure icons are imported if not already

const Footer = () => {
    return (
        <footer className="text-dark mt-5" style={{ backgroundColor: '#AEC15F' }}>
            <Container fluid>
                <Row className="py-3">
                    <Col xs={12} md={4} className="text-left text-md-left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/logo-rama.png" alt="Logo" height="50" style={{ marginRight: '10px' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="rama-tooltip">
                                            Real-Time Adaptive Modelling and Analysis
                                        </Tooltip>
                                    }
                                >
                                    <h4 style={{ marginBottom: '0', cursor: 'pointer', color: '#333333', fontWeight: 'bold' }}>ARAMA</h4>
                                </OverlayTrigger>
                                <small className="tagline-style" style={{ color: '#333333' }}>
                                    Your 24/7 AI Business Advisor
                                </small>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={4} className="text-center">
                        <h5 style={{ color: '#333333' }}>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="/about_us" style={{ color: '#333333' }}>About Us</a></li>
                            <li><a href="/faq" style={{ color: '#333333' }}>FAQ</a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4} className="text-right text-md-right d-flex flex-column align-items-md-end">
                        <h5 style={{ color: '#333333' }}>Contact Us</h5>
                        <p style={{ color: '#333333' }}>Email: care@arama.cloud</p>
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col xs={12} md={3} className="text-left">
                        <span style={{ color: '#333333' }}>© 2024 ARAMA</span>
                    </Col>
                    <Col xs={12} md={9} className="d-flex justify-content-end">
                        <a href="/tnc" style={{ color: '#333333', marginRight: '15px' }}>Terms and Conditions</a>
                        <a href="/privacy" style={{ color: '#333333' }}>Privacy Policy</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
