// src/components/public/FAQ_Page.js
// Component to show list of frequently asked questions

import React, { useEffect } from "react";
import { Accordion, Container, Row, Col } from "react-bootstrap";
import faqs from "./data/faq";
import Footer from "./Footer";

const FAQPage = ({ numToShow }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when the component mounts
    }, []);
    const displayedFaqs = faqs.slice(0, numToShow);

    return (
        <div className="faq-page">
            <Container fluid className="pb-4">
                <Row className="align-items-center justify-content-center text-left">
                    <Col className="content-style">
                        <h1>Empower Your Business with Data Insights</h1>
                        <p className="sub-text" style={{ color: '#252627' }}>
                            ARAMA simplifies data analysis and decision-making, helping you focus on growing your business with confidence.
                            Our AI-driven platform delivers clear, actionable insights, tailored to your specific goals.
                        </p>
                    </Col>
                    <Col className="home-banner content-style">
                        <img src="/images/home/features.jpg" alt="Banner" className="img-fluid trapezoid-img-left" />
                    </Col>
                </Row>
            </Container>
            <Container>
                <h1 className="mb-4">Frequently Asked Questions</h1>
                <Accordion defaultActiveKey="0">
                    {displayedFaqs.map(faq => (
                        <Accordion.Item eventKey={faq.id.toString()} key={faq.id}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>
                                {faq.answer}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Container>
            <Footer />
        </div>
    );
};

export default FAQPage;