import React, { useState } from "react";

const ContactUsForm = () => {
    const [language, setLanguage] = useState("EN");

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const getFormUrl = () => {
        if (language === "EN") {
            return "https://tally.so/embed/3l00e6?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1";
        } else if (language === "DE") {
            return "https://tally.so/embed/mKPgZM?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"; // Replace with your German form URL
        }
    };

    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <label htmlFor="language-select" style={{ marginRight: "10px" }}>
                    Select Language:
                </label>
                <select
                    id="language-select"
                    value={language}
                    onChange={handleLanguageChange}
                    style={{ padding: "5px" }}
                >
                    <option value="EN">English</option>
                    <option value="DE">Deutsch</option>
                </select>
            </div>
            <iframe
                src={getFormUrl()}
                width="100%"
                height="440px"
                title="contact_us"
            >
                Loading...
            </iframe>
        </div>
    );
};

export default ContactUsForm;
