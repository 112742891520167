import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import '../../custom.css';

function NavBar() {
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand href="/" style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src="/logo-rama.png"
                        width="60" // Increased from 40 to 60
                        height="60" // Increased from 40 to 60
                        className="d-inline-block align-top"
                        alt="ARAMA Logo"
                    />
                    <span style={{ fontSize: '1.8rem', fontWeight: 'bold', marginLeft: '7px', fontFamily: 'Ubuntu, sans-serif' }}>
                        ARAMA
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">  {/* Changed from me-auto to ms-auto */}
                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about_us">
                            <Nav.Link>About</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Link>Contact</Nav.Link>
                        </LinkContainer>
                        {/* 
                        <Nav.Link href={process.env.REACT_APP_NEXT_JS_URL} target="_blank" rel="noopener noreferrer">
                            Login
                        </Nav.Link>
                        */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
