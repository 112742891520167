import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../custom.css';
import Footer from './Footer';
import ContactUsForm from './ContactUsForm';

function About() {
    return (
        <div className='about-us-page'>
            <Container fluid className="pb-5">
                <Row className="align-items-center justify-content-center text-left">
                    <Col className="home-banner content-style">
                        <img src="/images/about_us/calm_about_us_saarthi.jpg" alt="Business Growth" className="img-fluid trapezoid-img-right" />
                    </Col>
                    <Col className="content-style">
                        <h2 style={{ color: '#f5413a' }}>Who Are We?</h2>
                        <p className="sub-text" style={{ color: '#252627' }}>
                            We are a team that lives and breathes data. Our goal is to empower small and medium-sized businesses by turning data into actionable insights—making AI accessible for all, not just big corporations.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Row className="align-items-center justify-content-center text-center">
                    <Col xs={12} sm={7} className="content-style bordered-title">
                        <h2>Our Mission</h2>
                        <p>
                            Our mission is to make data work for you. We transform complex data into clear insights, allowing your business to grow and succeed. We handle the analytics so you can focus on what matters most—whether launching new products or improving customer experiences.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="mb-5 py-5">
                <Row>
                    <Col sm={5} md={5} lg={5} xl={5} className="content-style">
                        <img src="/images/about_us/time.jpg"
                            alt="Safety"
                            className="img-fluid trapezoid-img-top-left-bottom-right"
                            style={{
                                width: '100%',
                                height: '500px',
                                objectFit: 'cover',
                                objectPosition: 'bottom' /* Crop from the top */
                            }}
                        />
                    </Col>
                    <Col sm={7} md={7} lg={7} xl={7} className="content-style">
                        <h3 className="mb-2 pb-2" style={{ color: '#f5413a' }}>Our Values: The 3C Approach</h3>
                        <p className="sub-text">
                            Our 3C Approach shapes everything we do.
                        </p>

                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-clipboard-data value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ustomer</h4>
                                <p>
                                    Our customers are our priority, and customer service is sacred. We measure our success with that of our customers.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-people value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ommunity</h4>
                                <p>
                                    Small and medium-sized businesses are the backbone of any economy. We support them by fostering a culture where data becomes a natural part of daily business, empowering growth and profitability.
                                </p>
                            </div>
                        </div>

                        <div className="d-flex align-items-center my-3">
                            <i className="bi bi-building value-icon"></i>
                            <div className="value-content">
                                <h4><span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>C</span>ompany</h4>
                                <p>
                                    We aim to build a company grounded in ethics and the greater good—one that our customers can trust, delivering consistent value for mutual success.
                                </p>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
            <Container fluid className="mb-5">
                <Row>
                    <Col md={6} className="text-left content-style">
                        <h3 className="mb-4">Why Choose Us?</h3>
                        <p className="sub-text">
                            Running a business is hard. Understanding your data shouldn't be. Our platform makes analytics simple, offering tools that help you grow with confidence.
                        </p>
                        <p className="sub-text" style={{ marginTop: '20px' }}>
                            We take your business seriously. Instead of automated sign-ups, we speak with every customer to understand their challenges and how we can help.
                        </p>
                        <p className="sub-text">
                            We are passionate about data and dedicated to customer service. Our commitment is to support you every step of the way and make sure you get the most from our platform.
                        </p>
                    </Col>
                    <Col md={6} className="text-center content-style">
                        <img src="/images/about_us/trust.jpg" alt="Why Choose Us?" className="img-fluid trapezoid-img-bottom-left" />
                    </Col>
                </Row>
            </Container>


            <Container fluid>
                <Row>
                    <Col xs={4} className="text-left">
                        <h2>Contact Us</h2>
                        <p>
                            Ready to see how we can help your business grow? Get in touch with us today!
                        </p>
                    </Col>
                    <Col>
                        <ContactUsForm />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default About;
